<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5317 0H16.4588C7.64501 0 0.5 7.14713 0.5 15.9636V16.0364C0.5 24.8529 7.64501 32 16.4588 32H16.5317C25.3456 32 32.4906 24.8529 32.4906 16.0364V15.9636C32.4906 7.14713 25.3456 0 16.5317 0Z"
      fill="#383838"
    />
    <path
      d="M6.55976 15.4554C6.60459 15.433 6.64944 15.4117 6.69315 15.3915C7.45304 15.0395 8.22302 14.7099 8.99188 14.3803C9.03335 14.3803 9.10282 14.3321 9.14205 14.3164C9.20145 14.2906 9.26086 14.2659 9.32026 14.2401C9.43458 14.1908 9.54891 14.1426 9.66211 14.0933C9.89075 13.9957 10.1183 13.8982 10.3469 13.8007L11.7154 13.2143C12.6277 12.8242 13.5411 12.4329 14.4535 12.0428C15.3658 11.6526 16.2792 11.2613 17.1915 10.8712C18.1039 10.481 19.0173 10.0898 19.9296 9.69962C20.8419 9.30948 21.7554 8.91821 22.6677 8.52806C22.8706 8.44061 23.0902 8.31056 23.3077 8.27244C23.4904 8.23993 23.6685 8.17715 23.8524 8.14239C24.2009 8.07625 24.5854 8.04934 24.9193 8.19397C25.0348 8.24442 25.1413 8.31505 25.2298 8.40361C25.6535 8.82291 25.5941 9.51127 25.5044 10.101C24.8801 14.211 24.2558 18.3221 23.6304 22.4321C23.5453 22.9961 23.4287 23.6149 22.9837 23.9714C22.6072 24.273 22.0714 24.3066 21.6063 24.1788C21.1412 24.0499 20.731 23.7797 20.3286 23.514C18.6598 22.4086 16.9898 21.3032 15.321 20.1977C14.9242 19.9354 14.4826 19.5923 14.4871 19.1159C14.4893 18.8289 14.6608 18.5733 14.8356 18.3457C16.2859 16.4532 18.3784 15.1527 19.9352 13.3477C20.1549 13.0932 20.3275 12.6336 20.026 12.4867C19.8467 12.3993 19.6404 12.5181 19.4768 12.6313C17.419 14.0608 15.3624 15.4913 13.3047 16.9207C12.6333 17.3871 11.9295 17.867 11.1203 17.9813C10.3962 18.0844 9.66772 17.8826 8.96723 17.6764C8.37994 17.5037 7.79375 17.3266 7.20982 17.1438C6.89936 17.0474 6.57881 16.9431 6.33896 16.7245C6.09912 16.5059 5.96128 16.1382 6.10586 15.8467C6.19664 15.664 6.37261 15.5485 6.55754 15.4543L6.55976 15.4554Z"
      fill="#FEFFFC"
    />
  </svg>
</template>
