<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5141 0H16.4413C7.62745 0 0.482422 7.14713 0.482422 15.9636V16.0364C0.482422 24.8529 7.62745 32 16.4413 32H16.5141C25.328 32 32.473 24.8529 32.473 16.0364V15.9636C32.473 7.14713 25.328 0 16.5141 0Z"
      fill="#383838"
    />
    <path
      d="M21.4286 6.51074H11.5298C8.79507 6.51074 6.57031 8.73616 6.57031 11.4717V20.5303C6.57031 23.2658 8.79507 25.4912 11.5298 25.4912H21.4286C24.1633 25.4912 26.388 23.2658 26.388 20.5303V11.4717C26.388 8.73616 24.1633 6.51074 21.4286 6.51074ZM8.31986 11.4717C8.31986 9.70144 9.76007 8.2608 11.5298 8.2608H21.4286C23.1983 8.2608 24.6385 9.70144 24.6385 11.4717V20.5303C24.6385 22.3005 23.1983 23.7412 21.4286 23.7412H11.5298C9.76007 23.7412 8.31986 22.3005 8.31986 20.5303V11.4717Z"
      fill="white"
    />
    <path
      d="M16.4784 20.6148C19.0215 20.6148 21.0915 18.5452 21.0915 16.0003C21.0915 13.4553 19.0226 11.3857 16.4784 11.3857C13.9342 11.3857 11.8652 13.4553 11.8652 16.0003C11.8652 18.5452 13.9342 20.6148 16.4784 20.6148ZM16.4784 13.1369C18.0576 13.1369 19.342 14.4217 19.342 16.0014C19.342 17.581 18.0576 18.8658 16.4784 18.8658C14.8992 18.8658 13.6148 17.581 13.6148 16.0014C13.6148 14.4217 14.8992 13.1369 16.4784 13.1369Z"
      fill="white"
    />
    <path
      d="M21.5207 12.1319C22.2057 12.1319 22.764 11.5745 22.764 10.8882C22.764 10.2019 22.2068 9.64453 21.5207 9.64453C20.8345 9.64453 20.2773 10.2019 20.2773 10.8882C20.2773 11.5745 20.8345 12.1319 21.5207 12.1319Z"
      fill="white"
    />
  </svg>
</template>
