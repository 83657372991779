<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5044 0H16.4315C7.61768 0 0.472656 7.14713 0.472656 15.9636V16.0364C0.472656 24.8529 7.61768 32 16.4315 32H16.5044C25.3182 32 32.4632 24.8529 32.4632 16.0364V15.9636C32.4632 7.14713 25.3182 0 16.5044 0Z"
      fill="#383838"
    />
    <path
      d="M24.2009 11.5766V14.598C23.673 14.5464 22.987 14.4265 22.2283 14.1484C21.2375 13.7852 20.5 13.2885 20.017 12.904V19.0107L20.0046 18.9916C20.0125 19.1127 20.017 19.236 20.017 19.3605C20.017 22.3931 17.5501 24.8618 14.5173 24.8618C11.4844 24.8618 9.01758 22.3931 9.01758 19.3605C9.01758 16.3279 11.4844 13.858 14.5173 13.858C14.8143 13.858 15.1057 13.8816 15.3904 13.9276V16.9052C15.1169 16.8077 14.8232 16.755 14.5173 16.755C13.0815 16.755 11.9126 17.9232 11.9126 19.3605C11.9126 20.7978 13.0815 21.966 14.5173 21.966C15.953 21.966 17.122 20.7966 17.122 19.3605C17.122 19.3067 17.1209 19.2529 17.1175 19.199V7.33203H20.1358C20.147 7.58765 20.1571 7.84551 20.1683 8.10112C20.1885 8.6045 20.3678 9.0877 20.6805 9.48345C21.047 9.94872 21.5883 10.4891 22.3482 10.9207C23.0599 11.3232 23.7279 11.497 24.2009 11.5788V11.5766Z"
      fill="white"
    />
  </svg>
</template>
