<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.4797 16.0006C32.4797 24.0816 26.4913 30.7623 18.712 31.8464C17.9846 31.9473 17.2404 32 16.485 32C15.613 32 14.7567 31.9305 13.9228 31.796C6.30711 30.5695 0.490234 23.9638 0.490234 16.0006C0.490234 7.16393 7.65205 0 16.4861 0C25.3201 0 32.4819 7.16393 32.4819 16.0006H32.4797Z"
      fill="#383838"
    />
    <path
      d="M18.7118 12.8482V16.3337H23.0224L22.3398 21.029H18.7118V31.8466C17.9844 31.9475 17.2402 32.0002 16.4848 32.0002C15.6128 32.0002 14.7566 31.9307 13.9227 31.7962V21.029H9.94727V16.3337H13.9227V12.069C13.9227 9.42316 16.0668 7.27734 18.7129 7.27734V7.27959C18.7208 7.27959 18.7275 7.27734 18.7353 7.27734H23.0235V11.338H20.2215C19.3888 11.338 18.7129 12.0141 18.7129 12.8471L18.7118 12.8482Z"
      fill="white"
    />
  </svg>
</template>
